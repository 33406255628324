function getRuntimeConfig() {
  // in production, consume the config JSON provided during client deployment
  if (process.env.NODE_ENV === 'production') {
    return JSON.parse(
      document.getElementById('azure-json-config').innerHTML
    );
  }
  // otherwise, if not in production, use local env vars
    // AUTH0_DOMAIN and PUBLIC_URL are necessary to run the app locally.  in production, these
    // are provided by the pipeline and don't need to be added to the variable library
  else return {
    REACT_APP_API: process.env.REACT_APP_API,
    REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENTID: process.env.REACT_APP_AUTH0_CLIENTID,
    REACT_APP_AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
    REACT_APP_COMPANY_LOGO: process.env.REACT_APP_COMPANY_LOGO,
    REACT_APP_PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL,
    REACT_APP_AZURE_AUTH: process.env.REACT_APP_AZURE_AUTH,
    REACT_APP_AZURE_CLIENTID: process.env.REACT_APP_AZURE_CLIENTID,
    REACT_APP_AZURE_API_CLIENTID: process.env.REACT_APP_AZURE_API_CLIENTID,
    REACT_APP_AZURE_TENANTID: process.env.REACT_APP_AZURE_TENANTID
  }
}

const config = getRuntimeConfig();

export default config;
