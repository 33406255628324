import { createSlice } from '@reduxjs/toolkit';

const savedCatalog = localStorage.getItem('catalog');

const catalogSlice = createSlice({
  name: 'catalog',
  initialState: savedCatalog ? savedCatalog : '',
  reducers: {
    setCatalog(state, action) {
      return action.payload
    }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = catalogSlice
// Extract and export each action creator by name
export const { setCatalog } = actions
// Export the reducer, either as a default or named export
export default reducer
