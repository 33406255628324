import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    accessToken: null,
    permissions: null
  },
  reducers: {
    setToken(state, action) {
      state.accessToken = action.payload
    },
    setPermissions(state, action) {
      state.permissions = action.payload
    }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = userSlice
// Extract and export each action creator by name
export const { setToken, setPermissions } = actions
// Export the reducer, either as a default or named export
export default reducer
