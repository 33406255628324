import React from 'react';
import { Button } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useSelector } from 'react-redux';
import { MenuOutlined } from '@ant-design/icons'
import { getCatalog } from '../../redux/selectors';
import config from "../../app-config";

/**
 * Sticky header used in app's layout. Shows logos, currently-selected catalog, logout button,
 * and button used to expand/collapse app's sidebar.
 *
 * When rendered by special SelectCatalogView UI, DefaultHeader is reused but does not show current catalog
 * or sidebar expander button.  This is because not much can be done w/o a catalog set -
 * it is a 'top-level' piece of data and is a param in most API requests
 *
 * @module DefaultHeader
 * @component
 */
export default function DefaultHeader(props: any) {
  const { isAuthenticated: isAuthenticatedAuth0, logout } = useAuth0();
  const { instance } = useMsal();
  const isAuthenticatedAzure = useIsAuthenticated();
  const { hideCatalog,
    collapsed, setCollapsed,
    collapseType, setCollapseType,
    sidebarWidth, setSidebarWidth
  } = props;

  const catalog = useSelector(getCatalog);

  return (
    <React.Fragment>
      {!hideCatalog && (
        <React.Fragment>
          <Button className='navbar-toggler' type='text'
                  onClick={() => {
                    // if collapsing on click,
                    if (!collapsed) {
                      // collapse menu
                      setCollapsed(true)
                      // indicate full collapse
                      setCollapseType('zero')
                    }
                    // if currently collapsed/minimized,
                    else if (collapsed && collapseType === 'minimized') {
                      // maintain setCollapsed(true)
                      // collapse down to zero
                      setCollapseType('zero')
                      // indicate sider was minimized
                      setSidebarWidth('minimized')
                    }
                    // if currently collapsed/zero,
                    else {
                      // if prior state was collapsed/minimized,
                      if (sidebarWidth === 'minimized') {
                        // maintain setCollapsed(true)
                        // go back to minimized
                        setCollapseType('minimized')
                        // set sidebar width correctly
                        setSidebarWidth('minimized')
                      } else {
                        setCollapsed(false)
                        // and update collapsed state/sidebar width
                        setCollapseType('full')
                        setSidebarWidth('full')
                      }
                    }
                  }
          }><MenuOutlined /></Button>
        </React.Fragment>
      )}
      <img
        src={`${config.REACT_APP_PUBLIC_URL}/assets/img/logo.png`}
        alt="channel software logo"
        className="header__img"
      />
      {!hideCatalog && (
        <p className='current-catalog'> Catalog: {catalog}</p>
      )}
      <img
        src={config.REACT_APP_COMPANY_LOGO}
        alt="client logo"
        className="header__logo-img ml-auto"
      />
      {/*logout button */}
      {(isAuthenticatedAuth0 || isAuthenticatedAzure) && (
        <Button
          className="btn-logout btn-danger"
          color="danger"
          onClick={() => {
            if (config.REACT_APP_AZURE_AUTH === 'true') {
              instance.logoutRedirect()
            }
            else if (config.REACT_APP_AZURE_AUTH !== 'true') {
              logout({
                clientId: config.REACT_APP_AUTH0_CLIENTID,
                logoutParams: {
                  returnTo: `${window.location.origin}${config.REACT_APP_PUBLIC_URL}/login`
                }
              })
            }
          }}
        >
          Logout
        </Button>
      )}
    </React.Fragment>
  );
}



