import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons'
import { Card, CardBody, Container } from 'reactstrap';
import { AppRoute } from "../models/App";
import SupportInfo from "./SupportInfo";
import { useSelector } from "react-redux";
import { getServiceExpirationDate } from "../redux/selectors";
import { checkDateValidity } from "../helpers/expirationDate-helpers";

/**
 * Component that conditionally renders help button/modal and the helpCard prop for the current route.
 * @module HelpInfoModal
 * @component
 */

export default function HelpInfoModal({ activeRoute }: { activeRoute: AppRoute  }) {

  // retrieve service expiration date from feature flags in store so it can be shown in help modal
  const expirationDate = useSelector(getServiceExpirationDate)

  const [showHelpCard, setShowHelpCard] = useState(false);

  // check the value of the expiration date:
  // if date is invalid or missing, modal text will indicate there's a problem with the value
  const isValidDate = checkDateValidity(expirationDate)

  const HelpCard = (activeRoute && activeRoute.helpCard) ? activeRoute.helpCard : null;

  return (
    <React.Fragment>
      {(activeRoute && HelpCard) && (
        <div>
          <Button
            title="Page help"
            aria-label='open help modal'
            className="open-help-modal header-modal"
            shape="circle"
            size="small"
            onClick={() => setShowHelpCard(true)}>
            <QuestionCircleFilled className="help-modal-icon"/>
          </Button>
          <Modal
            wrapClassName="help-info-modal"
            open={showHelpCard}
            destroyOnClose={true}
            onCancel={() => setShowHelpCard(false)}
            footer={[
              <Button
                key="help-modal-close"
                className="btn-help-modal btn-success"
                color="success"
                onClick={() => setShowHelpCard(false)}>
                Ok
              </Button>
            ]}>
            <h4>Console Help: {activeRoute?.name ? activeRoute.name : ''} page</h4>
            <Card style={{maxHeight: '40vh', overflow: 'auto'}}>
              <CardBody>
                <Container>
                  <HelpCard />
                </Container>
              </CardBody>
            </Card>
            <p>
              <a href="https://channelsoftware.sharepoint.com/:b:/s/e21Console/ETiHsfoVfcdConEiXMoaVJUBYlTCxjV6VNmlFewEbHYQCw?e=2aebth"
                 target="_blank" rel="noopener noreferrer">
                Click here
              </a> to view the Console User's Guide.
            </p>
            <p>
              <a href="https://www.channelsoftware.com/release-notes" target="_blank" rel="noopener noreferrer">
                Click here
              </a> to view the latest release notes.
            </p>
            {expirationDate && (
              <p>Your subscription contract expiration date is {isValidDate ? `${expirationDate}.` : 'invalid. Contact Channel Software Support for assistance.'}</p>
            )}
            <p>Need additional support? Contact us at <SupportInfo/>!</p>
          </Modal>
        </div>
      )}
    </React.Fragment>
  )
}
