import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import  { Helmet } from 'react-helmet'
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth0Provider } from "@auth0/auth0-react";
import { ReactQueryDevtools } from 'react-query/devtools'
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig.js";
import App from './App';
import store from './redux/store';
import config from "./app-config";
import initializeAgGrid from './helpers/agGridLoader'

// provision enterprise ag-grid and register modules
initializeAgGrid()


// client for react-query; assists in caching query data
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      // default stale time of 5 min
      staleTime: 1000 * 60 * 5,
      // if query is stale, refetch when its useQuery hook is mounted
      // (can override this to refetch immediately w/ refetchInactive)
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  }
});

// A function that routes the user to the right place after login
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

// While we migrate to an auth flow that uses Azure AD instead of Auth0, we'll need to support both paths.
// The wrapping Auth provider will change based on which path we're currently using.
// First, assign the common child components to a reusable variable:
const AppGuts = (
  <React.Fragment>
    <Helmet>
      <title>CSX Admin Console</title>
    </Helmet>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools  panelProps={{style: {bottom: '50px'}}}/>
      <App />
    </QueryClientProvider>
  </React.Fragment>
)

// The path will be dictated by the REACT_APP_AZURE_AUTH env var.  If true, wrap the common child components
// in the Azure auth provider:
let AuthWrapper;
if (config.REACT_APP_AZURE_AUTH === 'true') {
  console.log('az wrap')
  // MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
  //  For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
  const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }
  msalInstance.addEventCallback((event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload.account
    ) {
      msalInstance.setActiveAccount(event.payload.account);
    }
  });

  AuthWrapper = (
    <MsalProvider instance={msalInstance}>
      {AppGuts}
    </MsalProvider>
  )
} else {
  console.log('a0 wrap')
  // otherwise, if the env var is falsey, wrap the common child components in the Auth0 provider:
  AuthWrapper = (
    <Auth0Provider
      domain={config.REACT_APP_AUTH0_DOMAIN}
      clientId={config.REACT_APP_AUTH0_CLIENTID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}${config.REACT_APP_PUBLIC_URL}`,
        audience: config.REACT_APP_AUTH0_AUDIENCE
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {AppGuts}
    </Auth0Provider>
  )
}

// support for earlier safari versions
if (window.crypto && !window.crypto.subtle && window.crypto.webkitSubtle) {
  window.crypto.subtle = window.crypto.webkitSubtle;
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    {AuthWrapper}
  </Provider>
);

