import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { getPermissions, getFeatureFlags } from '../redux/selectors';
import createFeatureDecisions from '../auth/featureDecisions'

// useFeature is a custom hook that enables feature flags to respond to store changes in components
// example use case: Resource Center UI visibility is dictated by the value of the feature flag exposeResourceCenter.
// if the user changes the value of this flag, the store must be able to set the new flag value
// in memory immediately to avoid timing issues that may result in Resource Center UI being shown/hidden
// out of sync w/ the flag's value

// create a memoized selector for feature decisions
// this seems to be the most efficient way of keeping featureDecisions up to date w latest store data
// avoids calling createFeatureDecisions unless a store dependency has changed
const featureDecisionSelector = createSelector(
  // 'input' selector - extracts values from arguments
  getPermissions,
  // 'input' selector
  getFeatureFlags,
  // 'input' selector
  // get latest analytics flag. could abstract this selector, like the others
  state => state.analytics,
  // 'output' selector - receives extracted values and returns a derived value.  See featureDecisions.ts.
  // will only be called if extracted values change - for example, if the user's permissions change or a
  // feature flag value is updated via the UI
  createFeatureDecisions
);

// called in the app as 'useFeature' hook
export default function(featureFunction) {
  // use memoized selector created above to get an up-to-date featureDecision obj
  const featureDecisions = useSelector(featureDecisionSelector);
  // then call the feature function with the up-to-date obj
  return featureFunction(featureDecisions)
}
