import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { RouteComponentProps } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import useFeature from "./hooks/useFeature";
import {
  getCatalog,
  getPermissions,
  getToken,
  isCatalogDataLoaded,
  isCatalogSelected,
  isFeatureFlagsLoaded,
  isGAConfigFetched,
  isPermissionsLoaded,
  isTokenLoaded
} from './redux/selectors';
import { setToken, setPermissions } from './redux/slices/user.slice';
import { setAnalytics } from './redux/slices/analytics.slice';
import { setCatalogData } from './redux/slices/catalogData.slice';
import { setFeatureFlags } from './redux/slices/featureFlags.slice'
import ErrorFallback from './components/ErrorFallback';
import AntDefaultLayout from './containers/DefaultLayout/AntDefaultLayout';
import Loading from './components/Loading';
import LoadingLayout from "./views/Pages/LoadingLayout";
import { Login, SelectCatalogView } from './views/Pages';
import PrivateRoute from "./components/PrivateRoute";
import { fetchCatalog, getAnalyticsConfig, fetchFeatureFlags } from './data/api';
import routes from './routes';
import config from "./app-config";
import { loginScopes } from "./authConfig";
/// Ag-Grid styles
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';
// antd UI styles
import 'antd/dist/antd.css';
// react-tooltip styles
import 'react-tooltip/dist/react-tooltip.css'
// app styles
import './scss/style.css';
import './scss/App.css';
import './scss/DefaultLayout.css'
import './scss/SelectCatalog.css';
import './scss/Dashboard.css'
import './scss/DragDrop.css'
import './scss/Google.css'
import './scss/HelpInfoModal.css'
import './scss/ImportView.css'
import './scss/OrderHistory.css'
import './scss/ProductsView.css'
import './scss/ProductForm.css'
import './scss/ResourceCenter.css'
import './scss/PreviewResource.css'
import './scss/SearchManagement.css'
import { FeatureDecisions } from "./models/App";

const App = () => {
  const { isAuthenticated: isAuthenticatedAuth0, isLoading, user, getAccessTokenSilently } = useAuth0();
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticatedAzure = useIsAuthenticated();

  // retrieve initial state and 'existence' flags from store with redux hooks
  const token = useSelector(getToken);
  const tokenLoadedFlag = useSelector(isTokenLoaded);
  const permissions = useSelector(getPermissions);
  const permissionsLoadedFlag = useSelector(isPermissionsLoaded);
  const catalog = useSelector(getCatalog);
  const catalogSelectedFlag = useSelector(isCatalogSelected);
  const catalogDataLoadedFlag = useSelector(isCatalogDataLoaded);
  const gaConfigFetchedFlag = useSelector(isGAConfigFetched);
  const featureFlagsLoadedFlag = useSelector(isFeatureFlagsLoaded)
  const showAnalytics = useFeature((features: FeatureDecisions) => features.showAnalytics());

  // ensures we don't get overlapping calls to getTokenSilently
  const [pendingTokenRequest, setPendingTokenRequest] = useState(false);
  // get a reference to redux dispatch fxn
  const dispatch = useDispatch();
  // get an up-to-date access token every time isAuthenticated changes
  // put it in redux store so it can be attached to API requests
  // see authAxiosRequest.js
  useEffect(() => {
    console.log('a0 token hook');
    console.log('a0 flag', isAuthenticatedAuth0);
    if (config.REACT_APP_AZURE_AUTH !== 'true') {
      const updateTokenFxn = async () => {
        try {
          console.log('getting token silently');
          // set pending request flag to avoid overlapping calls to getTokenSilently
          // otherwise, refetching a stale/invalid token can cause a rerendering loop
          setPendingTokenRequest(true);
          // user permissions extracted from ID token in effect below
          const token = await getAccessTokenSilently({
            authorizationParams: {
              audience: config.REACT_APP_AUTH0_AUDIENCE
            }
          });
          dispatch(setToken(token))
        } catch (err) {
          console.error(err)
        } finally {
          setPendingTokenRequest(false)
        }
      };
      if (isAuthenticatedAuth0 && !tokenLoadedFlag && !pendingTokenRequest) {
        updateTokenFxn()
      }
      // not sure if we ever encounter this state but it's good practice to handle it
      // nullify the token and permissions so the user has no access until re-auth'd
      else if (!isAuthenticatedAuth0 && !pendingTokenRequest && tokenLoadedFlag) {
        console.log('not authenticated but have a token');
        dispatch(setToken(null));
        dispatch(setPermissions([]))
      } else if (!isAuthenticatedAuth0 && !tokenLoadedFlag && !pendingTokenRequest) {
        console.log('no auth & no token')
        // proceed even though we're not authed and have no token.
        // protected routes have an effect that will trigger user to login if not already auth'd.
        // we can't trigger a login here b/c some pages (like /login) are not protected and don't require auth.
      } else {
        console.log('auth\'d and token ', token ? token.slice(-3) : 'none')
      }
    }
  }, [isAuthenticatedAuth0, tokenLoadedFlag, pendingTokenRequest]);

  useEffect(() => {
    console.log('az token hook');
    console.log('az flag', isAuthenticatedAzure);
    if (config.REACT_APP_AZURE_AUTH === 'true') {
      console.log('accounts', accounts)
      console.log('tokenLoaded', tokenLoadedFlag);
      console.log('pendingTokenReq', pendingTokenRequest);
      console.log(inProgress)
      if ((accounts.length > 0) && isAuthenticatedAzure && !tokenLoadedFlag && !pendingTokenRequest && inProgress === InteractionStatus.None) {
        console.log('getting token silently');
        // set pending request flag to avoid overlapping calls to getTokenSilently
        // otherwise, refetching a stale/invalid token can cause a rerendering loop
        setPendingTokenRequest(true);
        const request = {
          account: accounts[0],
          scopes: loginScopes.scopes
        }
        instance.acquireTokenSilent(request)
          .then(res => {
            dispatch(setToken(res.accessToken))
            console.log('stored az token ', res.accessToken ? res.accessToken.slice(-3) : 'none')
          })
          .catch(error => {
            console.log('acquireTokenSilent failed, falling back to acquireTokenRedirect')
            console.log(error)
            // acquireTokenSilent can fail for a number of reasons, fallback to interaction
            if (error instanceof InteractionRequiredAuthError) {
              // instance.acquireTokenPopup(request).then(res => {
              // return promise so .finally will not run until acquireTokenRedirect is resolved.
              // we don't want to call setPendingTokenRequest(false) if acquireTokenRedirect is in-process -
              // it re-runs this effect and will make a redundant call to acquireTokenSilent
              return instance.acquireTokenRedirect(request)
                .then(res => {
                  console.log('stored az token from redirect')
                  //@ts-ignore
                  dispatch(setToken(res.accessToken))
                });
            }

          })
          .finally(() => {
            console.log('setPendingTokenRequest to false')
            setPendingTokenRequest(false)
          })
      }
      // not sure if we ever encounter this state but it's good practice to handle it
      // nullify the token and permissions so the user has no access until re-auth'd
      else if (!isAuthenticatedAzure && !pendingTokenRequest && tokenLoadedFlag) {
        console.log('not authenticated but have a token');
        dispatch(setToken(null));
        dispatch(setPermissions([]))
      } else if (!isAuthenticatedAzure && !tokenLoadedFlag && !pendingTokenRequest) {
        console.log('no auth & no token')
        // proceed even though we're not authed and have no token.
        // protected routes have an effect that will trigger user to login if not already auth'd.
        // we can't trigger a login here b/c some pages (like /login) are not protected and don't require auth.
      }
    }
  }, [isAuthenticatedAzure, accounts, tokenLoadedFlag, pendingTokenRequest, inProgress])

  useEffect(() => {
    console.log('getting user')
    if (isAuthenticatedAuth0) {
      if (user) {
        console.log('user loaded')
        let userAuthObject = user['http://www.channelsoftware.com/user-authorization'];
        // get user roles from id token (if relevant auth0 action is added to login flow):
        if (userAuthObject && userAuthObject.roles && userAuthObject.roles.length > 0) {
          dispatch(setPermissions([...userAuthObject.roles]));
        }
      } else {
        console.log('No ID token')
        // todo: add error handling to display message to user
      }
    }
    if (isAuthenticatedAzure) {
      // can't assign custom roles to our AD users at our current pricing tier -
      // instead, parse the username for the '@channelsoftware.com' domain
      // and dispatch the 'Channel Admin' permission manually
      if (accounts[0] && accounts[0].username && accounts[0].username.toLowerCase().includes('@channelsoftware.com')) {
        dispatch(setPermissions(['Channel Admin']))
      } else {
        dispatch(setPermissions([]))
      }
    }
  }, [isAuthenticatedAuth0, isAuthenticatedAzure, user, accounts])

  // if we have API access and a catalog, fetch the feature flag values from the API
  // and put them in redux.  Flag values populate some fields in the
  // feature decision factory that are used to show/hide various UI elements
  useEffect(() => {
    if ((isAuthenticatedAuth0 || isAuthenticatedAzure) && tokenLoadedFlag && catalogSelectedFlag && !featureFlagsLoadedFlag) {
      console.log('getting flags')
      fetchFeatureFlags(catalog).then(({data}) => {
        console.log('flags loaded')
        dispatch(setFeatureFlags(data))
      }).catch(err => {
        // if endpoint throws an error, set an empty obj in
        // redux to make all flag checks falsey (hide content by default)
        console.log(err);
        dispatch(setFeatureFlags([]))
      })

    }
  }, [catalog, isAuthenticatedAuth0, isAuthenticatedAzure, tokenLoadedFlag, catalogSelectedFlag, featureFlagsLoadedFlag])

  // if we have API access, a catalog name from redux/localStorage, and no catalog data,
  // fetch catalog data so it can be put in Redux
  useEffect(() => {
    if ((isAuthenticatedAuth0 || isAuthenticatedAzure) && tokenLoadedFlag && catalogSelectedFlag && !catalogDataLoadedFlag) {
      console.log('getting catalog')
      fetchCatalog(catalog).then(({data}) => {
        console.log('catalog loaded')
        dispatch(setCatalogData(data))
      }).catch(err => {
        console.log(err)
      })
    }
  }, [catalog, catalogDataLoadedFlag, catalogSelectedFlag, tokenLoadedFlag, isAuthenticatedAuth0, isAuthenticatedAzure]);

  // if we have API access, a catalog name from redux, and no showAnalytics (google analytics) key in localStorage,
  // check if a GA config exists so DefaultLayout can hide GA data if needed
  // this effect is called when site is loaded for the 1st time in user's browser, or is viewed in private browser
  useEffect(() => {
    if ((isAuthenticatedAuth0 || isAuthenticatedAzure) && tokenLoadedFlag && catalogSelectedFlag && !gaConfigFetchedFlag && !showAnalytics) {
      console.log('getting analytics')
      getAnalyticsConfig(catalog).then((res) => {
        console.log('analytics loaded')
        dispatch(setAnalytics(res.status === 200));
      }).catch(err => {
        console.log(err);
        dispatch(setAnalytics(false))
      })
    }
  }, [catalog, catalogSelectedFlag, tokenLoadedFlag, gaConfigFetchedFlag, isAuthenticatedAuth0, isAuthenticatedAzure]);

  // ensure that the auth module (azure or auth0) is initialized, AND that the user has a token if they're auth'd
  // it's OK to not be auth'd at this point - non-auth'd users must be able to reach the <Login/> route,
  // and protected routes have logic to require login if user is not auth'd
  // the check below catches the edge case where the auth module is initialized but
  // is in the process of retrieving a token and storing it, causing API requests to fail.

  // it's also OK to not have feature flags loaded yet - routes dependent on feature flags will not load
  // until flags are loaded due to the featureFlagsLoadedFlag check in the <Switch/> below

  // return loading if the auth module is initializing
  if (((inProgress !== InteractionStatus.None) && (inProgress !== InteractionStatus.Login))
    || ((config.REACT_APP_AZURE_AUTH !== 'true') && isLoading)
    // return loading if we're auth'd but have no token
    || (isAuthenticatedAuth0 && !tokenLoadedFlag)
    || (isAuthenticatedAzure && !tokenLoadedFlag)) {
    return (
      <Loading />
    );
  }
  console.log('public URL: ' + config.REACT_APP_PUBLIC_URL);
  return (
    <Router basename={config.REACT_APP_PUBLIC_URL}>
      <Switch>
        {/* Login route must be unprotected, because users will not yet be auth'd */}
        <Route exact path="/login">
          <Login />
        </Route>
        {/*
        * Restrict access to the rest of the application with PrivateRoutes, which run an auth check.
        * SelectCatalog routes must go here, before the check for featureFlagsLoadedFlag,
        * because a catalog is required in order to fetch the flags
        */}
        <PrivateRoute exact path="/select-catalog" component={SelectCatalogView} />
        {/* Do not allow users to access further routes without first selecting a catalog. */}
        {!catalogSelectedFlag && <Redirect to="/select-catalog" />}
        {/*
        * to show UI for any other route in the app, users must first have the feature flags loaded.
        * Feature flags determine which items are shown in the sidebar and which routes users have access to
        * Do not allow users to access further routes without loaded flags. If flags are in the process of loading,
        * show a loading spinner w/ limited header. This check is done here, instead of before the <Router> is returned,
        * in order to avoid re-render timing issues with redirecting after selecting a catalog.
        */}
        {!featureFlagsLoadedFlag && (
          <PrivateRoute component={LoadingLayout}/>
        )}
        {/* nested switch statements prevent users from being returned to their last location on login, so we can't use
            <Route path='/' component={DefaultLayout}/>
        * where DefaultLayout exposes the current component with another Switch.
        * Instead we map the routes here, passing the current component
        * already wrapped in DefaultLayout HOC, as a prop for PrivateRoute */}
        {routes.map((route, idx) => {
          if (route.component) {
            const InnerComponent = route.component;

            // UI route component wrapped with default layout
            const wrappedComponent = (props: RouteComponentProps) => (
              <AntDefaultLayout activeRoute={route}>
                <Suspense fallback={<Loading />}>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <InnerComponent {...props} />
                  </ErrorBoundary>
                </Suspense>
              </AntDefaultLayout>
            );

            // protected route; checks authentication before returning wrapped component
            return <PrivateRoute
              key={idx}
              path={route.path}
              exact
              // exact={route.exact}
              // name={route.name}
              component={wrappedComponent}
            />
          }
          return (null)
        })}
        {/* fallback redirect - if there's a mismatch in routing, route to the dashboard */}
        <Redirect from="/" to="/dashboard" />
      </Switch>
    </Router>
  );
};

export default App;

