import React from 'react';
import { Layout } from 'antd';
import DefaultHeader from "../../containers/DefaultLayout/DefaultHeader";
import Loading from "../../components/Loading";

const { Header } = Layout;

/**
 * Component to render a loading bouncer in a layout with a limited header (no catalog or sidebar btn).
 * Displayed as a fallback UI by App.tsx <Router> if user is authenticated and has a catalog but feature
 * flags are not yet loaded.
 * @module LoadingLayout
 * @component
 */
export const LoadingLayout = function LoadingLayout() {

  return (
    <React.Fragment>
      <Header className="app-header navbar">
        <DefaultHeader hideCatalog={true}/>
      </Header>
      <Loading/>
    </React.Fragment>
  )
}

export default LoadingLayout
