import moment from "moment";

/**
 * Assesses whether the current SAAS contract expiration date value is a valid date
 * @param {string} expirationDate - a string in the format MM-DD-YYYY
 * @returns {boolean}
 */
export const checkDateValidity = (expirationDate: string) => {
  return moment(expirationDate, "MM-DD-YYYY").isValid()
}

/**
 * Assesses whether the current SAAS contract expiration date value is within 14 days of today
 * @param {string} expirationDate - a string in the format MM-DD-YYYY
 * @returns {boolean}
 */
export const checkDateNearlyExpired = (expirationDate: string) => {
  return moment(expirationDate, "MM-DD-YYYY").isBefore(moment().add(14, "days"))
}

/**
 * Assesses whether the current SAAS contract expiration date value is before today's date
 * @param {string} expirationDate - a string in the format MM-DD-YYYY
 * @returns {boolean}
 */
export const checkDateExpired = (expirationDate: string) => {
  return moment(expirationDate, "MM-DD-YYYY").isBefore(moment())
}
