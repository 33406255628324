import { useState, useEffect } from 'react';
import { ApiResponse, AxiosReqStatus } from "../models/App";

// axiosRequest is the function indicating which API endpoint should be called
// other params are dependencies to trigger useEffect/refetch

/**
 * Custom hook handling API requests. Takes in a function for calling an axios request and any dependencies
 * causing the request to be refetched.  Returns an array with the API response payload, an error object
 * (if it exists), a setter for manually setting the request data in state (see ProductForm.tsx),
 * and a flag indicating whether the request is in process.
 * @param {Promise<ApiResponse<T>>} axiosRequest - the name of the axios request being sent to the API
 * @param {any} dependencies - a list of variables to be used as useEffect dependencies triggering the axios request
 */
export default function<T = any>(axiosRequest: () => Promise<ApiResponse<T>>, ...dependencies: any[]) {
  const [status, setStatus] = useState<AxiosReqStatus<T>>({} as AxiosReqStatus<T>);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    axiosRequest()
      .then((response) => {
          setStatus({ ...status, data: response.data as T });
        setIsLoading(false)
          return response.data
      })
      .catch((error) => {
        setStatus({ ...status, error })
        setIsLoading(false)
      });
  }, dependencies ? dependencies : []);

  return [status.data, status.error, setStatus, isLoading] as const
}
