import { createSlice } from '@reduxjs/toolkit';
import { UPDATE_FEATURE_FLAG } from '../actions/featureFlags.action';

const savedFlag = localStorage.getItem('showAnalytics');

// savedFlag is a string.  set initialState to be a boolean based on savedFlag value
// if localStorage doesn't have a showAnalytics key, initialState will be null
// null initialState indicates to the app that a google analytics config has not yet been fetched -
// so it will be fetched in App.tsx.

// if GET /google-analytics/config returns 404, no GA config was previously saved for this client, so
// showAnalytics in localStorage, redux's state.analytics AND our custom showAnalytics hook will all be false

// if GET /google-analytics/config returns 200, a GA config was previously saved for this client, so
// showAnalytics in localStorage, redux's state.analytics AND our custom showAnalytics hook will all be true

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: savedFlag ? savedFlag === 'true' : null,
  reducers: {
    setAnalytics(state, action) {
      // keep localStorage in sync w/ redux store
      localStorage.setItem('showAnalytics', action.payload);
      return action.payload
    }
  },
  // allow this slice to respond to action types it has not generated via the reducers key:
  // because featureFlags.slice must also respond to the UPDATE_FEATURE_FLAG action,
  // the featureFlag action specified here is generated in featureFlags.action
  // instead of in featureFlagsSlice.reducers to avoid circular reference issues
  extraReducers: (builder) => {
    builder
      .addCase(UPDATE_FEATURE_FLAG, (state, action) => {
        // if the exposeGoogleAnalytics flag is updated, set analytics to false
        // this will hide analytics UI until we've refetched GA config
        // so if a client is converting from UA to GA4, analytics UI won't be
        // shown until we're sure a valid GA4 config has been saved
        const flag = action.payload.featureName === 'exposeGoogleAnalytics' ? false : state
        // keep localStorage in sync w/ redux store
        localStorage.setItem('showAnalytics', flag);
        return flag
      })
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = analyticsSlice
// Extract and export each action creator by name
// no need to extract/export the UPDATE_FEATURE_FLAG action; it's already exported from featureFlags.action
export const { setAnalytics } = actions
// Export the reducer, either as a default or named export
export default reducer
