import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

const Loading = () => (
  <div data-testid='loading-bouncer' className='loading-bouncer'>
    <BeatLoader color="#DA3927" />
  </div>
);

export default Loading;
