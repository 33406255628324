import { createSlice } from '@reduxjs/toolkit';
import { UPDATE_FEATURE_FLAG } from '../actions/featureFlags.action';

const expirationDateSlice = createSlice({
  name: 'expirationDate',
  initialState: false,
  reducers: {
    confirmExpirationDateModal(state, action) {
      return action.payload
    }
  },
  // allow this slice to respond to action types it has not generated via the reducers key:
  // because expirationDate.slice must also respond to the UPDATE_FEATURE_FLAG action,
  // the featureFlag actions specified here are generated in featureFlags.action
  // instead of in expirationDateSlice.reducers to avoid circular reference issues
  extraReducers: (builder) => {
    builder
      .addCase(UPDATE_FEATURE_FLAG, (state, action) => {
        // if the ExpirationDate flag is updated, set confirmExpirationDateModal back to false
        return action.payload.featureName === 'ExpirationDate' ? false : state
      })
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = expirationDateSlice
// Extract and export each action creator by name
export const { confirmExpirationDateModal } = actions
// Export the reducer, either as a default or named export
export default reducer
