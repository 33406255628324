import React from 'react';

export default function ErrorFallback({error}: {error: Error}) {
  // default error message displayed by ErrorBoundary component
  console.error('error: ', error);
  return (
    <p style={{display: 'flex', justifyContent: 'center'}}
      className="form-validation">An error occurred. Check your browser console for more information.</p>
  )
}
