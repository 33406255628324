import React from 'react';
import { Link } from 'react-router-dom';
import {
  DatabaseOutlined,
  DesktopOutlined,
  FileOutlined, FolderOutlined,
  PictureOutlined, RiseOutlined, SettingOutlined,
  TeamOutlined, ReadOutlined
} from '@ant-design/icons';

/**
 * Function returning an array representing the sidebar tree with all links exposed.  This nav map will be consumed
 * in Sidebar.tsx.  The Sidebar will limit the exposed links per the current featureFlag values in redux.
 * It will also use this nav map to locate the user within the app based on their path so the sidebar
 * can open the correct submenus and highlight the correct link for that current path.
 *
 * When adding a new route to the app, must include it here to get it to appear in the sidebar.
 * @returns AntSidebarConfigItem[]
 */
export default function() {
  return [
    {
      // must be unique. by convention, use route's path
      key: '/dashboard',
      // antd icon to be shown for top-level items in sidebar UI
      icon: <DesktopOutlined />,
      // navigable routes/'leaf nodes' will have a label that is a Link.
      label: <Link to={'/dashboard'}>
        Dashboard
      </Link>
      // if a route was restricted by feature flags, it would also have a flagName key
      // with the value of the controlling feature flag as below. Eg, see User Maintenance -> Content Groups
      // flagName: 'exposeContentGroups'
    },
    {
      key: '/user-maintenance',
      icon: <TeamOutlined />,
      // non-navigable sidebar items have a label that is just text
      // can't navigate to a 'User Maintenance' UI component
      label: 'User Maintenance',
      // nodes that hold a submenu have children, which may or may not be navigable nodes themselves
      // (User Maintenance -> Customers vs User Maintenance -> Sales Reps)
      children: [
        {
          key: '/user-maintenance/customers',
          label: <Link to={'/user-maintenance/customers'}>
            Customers
          </Link>
        },
        {
          key: '/user-maintenance/logins',
          label:  <Link to={'/user-maintenance/logins'}>
            Logins
          </Link>
        },
        {
          key: '/user-maintenance/customer-numbers',
          label:  <Link to={'/user-maintenance/customer-numbers'}>
            Customer Numbers
          </Link>
        },
        {
          key: '/user-maintenance/shipping-methods',
          label:  <Link to={'/user-maintenance/shipping-methods'}>
            Shipping Methods
          </Link>
        },
        {
          key: '/user-maintenance/shipping-rates',
          flagName: 'exposeShipRates',
          label:  <Link to={'/user-maintenance/shipping-rates'}>
            Shipping Rates
          </Link>
        },
        {
          key: '/user-maintenance/shipping-addresses',
          flagName: 'exposeShipAddresses',
          label:  <Link to={'/user-maintenance/shipping-addresses'}>
            Shipping Addresses
          </Link>
        },
        {
          key: '/user-maintenance/terms',
          label:  <Link to={'/user-maintenance/terms'}>
            Terms
          </Link>
        },
        {
          key: '/user-maintenance/translations',
          label:  <Link to={'/user-maintenance/translations'}>
            Translations
          </Link>
        },
        {
          key: '/user-maintenance/content-groups',
          flagName: 'exposeContentGroups',
          label:  <Link to={'/user-maintenance/content-groups'}>
            Content Groups
          </Link>
        },
        {
          key: '/user-maintenance/warehouses',
          label:  <Link to={'/user-maintenance/warehouses'}>
            Warehouses
          </Link>
        },
        {
          key: '/user-maintenance/extra-billing',
          flagName: 'enableCostCenters',
          label:  <Link to={'/user-maintenance/extra-billing'}>
            Extra Billing
          </Link>
        },
        {
          key: '/user-maintenance/approval-groups',
          label: 'Approval Groups',
          flagName: 'exposeApprovalGroups',
          children: [
            {
              key: '/user-maintenance/approval-groups/groups',
              flagName: 'exposeApprovalGroups',
              label: <Link to={'/user-maintenance/approval-groups/groups'}>
                Approval Groups
              </Link>
            },
            {
              key: '/user-maintenance/approval-groups/budgets',
              flagName: 'exposeApprovalBudget',
              label:  <Link to={'/user-maintenance/approval-groups/budgets'}>
                Login Budgets
              </Link>
            }
          ]
        },
        {
          key: '/user-maintenance/sales-reps',
          label: 'Sales Reps',
          flagName: 'exposeSalesRep',
          children: [
            {
              key: '/user-maintenance/sales-reps/reps',
              label:  <Link to={'/user-maintenance/sales-reps/reps'}>
                Sales Reps
              </Link>
            },
            {
              key: '/user-maintenance/sales-reps/rep-types',
              label:  <Link
                to={'/user-maintenance/sales-reps/rep-types'}>
                Sales Rep Types
              </Link>
            }
          ]
        },
      ]
    },
    {
      key: '/product-maintenance',
      icon: <DatabaseOutlined />,
      label: 'Product Manager',
      children: [
        {
          key: '/product-maintenance/products',
          label: <Link
            to={'/product-maintenance/products'}>
            Products
          </Link>
        },
        {
          key: '/product-maintenance/categories',
          label:  <Link
            to={'/product-maintenance/categories'}>
            Categories
          </Link>
        },
        {
          key: '/product-maintenance/brands',
          label:  <Link
            to={'/product-maintenance/brands'}>
            Brands
          </Link>
        },
        {
          key: '/product-maintenance/coupons',
          flagName: 'exposeCoupons',
          label:  <Link
            to={'/product-maintenance/coupons'}>
            Coupons
          </Link>
        },
        {
          key: '/product-maintenance/search-management',
          label:  <Link
            to={'/product-maintenance/search-management'}>
            Search
          </Link>
        },
        {
          key: '/product-maintenance/product-backups',
          label:  <Link
            to={'/product-maintenance/product-backups'}>
            Product Backups
          </Link>
        }
      ]
    },
    {
      key: '/resource-maintenance',
      icon: <PictureOutlined/>,
      label: 'Resource Manager',
      children: [
        {
          key: '/resource-maintenance/asset-management',
          label: <Link
            to={'/resource-maintenance/asset-management'}>
            Assets
          </Link>
        },
        {
          key: '/resource-maintenance/overlays',
          label:  <Link
            to={'/resource-maintenance/overlays'}>
            Image Overlays
          </Link>
        },
        {
          key: '/resource-maintenance/resources',
          flagName: 'exposeResourceCenter',
          label:   <Link
            to={'/resource-maintenance/resources'}>
            Resources
          </Link>
        },
      ]
    },{
      key: '/import',
      icon: <FileOutlined />,
      label: <Link
        to={'/import'}>
        Import Data
      </Link>
    },
    {
      key: '/analytics',
      icon: <ReadOutlined />,
      label: 'Site Reports',
      children: [
        {
          key: '/analytics/ach-reports',
          flagName: 'exposeACHReports',
          label:  <Link
            to={'/analytics/ach-reports'}>
            ACH Reports
          </Link>
        },
        {
          key: '/analytics/order-history',
          flagName: 'exposeOrderHistory',
          label:  <Link
            to={'/analytics/order-history'}>
            Order History
          </Link>
        },
        {
          key: '/analytics/abandoned-cart-reports',
          label:  <Link
            to={'/analytics/abandoned-cart-reports'}>
            Abandoned Cart
          </Link>
        },
        {
          key: '/analytics/login-reports',
          label:  <Link
            to={'/analytics/login-reports'}>
            Login Reports
          </Link>
        },
        {
          key: '/analytics/successful-searches',
          label:  <Link
            to={'/analytics/successful-searches'}>
            Successful Searches
          </Link>
        },
        {
          key: '/analytics/failed-searches',
          label:  <Link
            to={'/analytics/failed-searches'}>
            Failed Searches
          </Link>
        },
        {
          key: '/analytics/usage-reports',
          label:  <Link
            to={'/analytics/usage-reports'}>
            Usage Reports
          </Link>
        }
      ]
    },
    {
      key: '/google',
      icon: <RiseOutlined />,
      label: 'Google Analytics',
      flagName: 'exposeAnalytics',
      children: [
        {
          key: '/google/audience-overview',
          flagName: 'showAnalytics',
          label:  <Link
            to={'/google/audience-overview'}>
            Audience Overview
          </Link>
        },
        {
          key: '/google/ecommerce-overview',
          flagName: 'showAnalytics',
          label:  <Link
            to={'/google/ecommerce-overview'}>
            Ecommerce Overview
          </Link>
        },
        {
          key: '/google/ga-config',
          label:  <Link
            to={'/google/ga-config'}>
            Settings
          </Link>
        }
      ]
    },
    {
      key: '/admin',
      icon: <SettingOutlined />,
      label: 'Admin',
      flagName: 'exposeAdminUI',
      children: [
        {
          key: '/admin/feature-flags',
          flagName: 'exposeAdminUI',
          label:  <Link
            to={'/admin/feature-flags'}>
            Feature Flags
          </Link>
        },
        {
          key: '/admin/users',
          flagName: 'exposeAzureUI',
          label:  <Link
            to={'/admin/users'}>
            Users
          </Link>
        },
        {
          key: '/admin/company-info',
          flagName: 'exposeAdminUI',
          label:  <Link
            to={'/admin/company-info'}>
            Company Info
          </Link>
        },
        {
          key: '/admin/websetup',
          label: 'WebSetup',
          flagName: 'exposeAdminUI',
          children: [
            {
              key: '/admin/websetup/countries',
              label:  <Link
                to={'/admin/websetup/countries'}>
                Countries
              </Link>
            },
            {
              key: '/admin/websetup/states',
              label:  <Link
                to={'/admin/websetup/states'}>
                States
              </Link>
            },
            {
              key: '/admin/websetup/custom-fields',
              label:  <Link
                to={'/admin/websetup/custom-fields'}>
                Custom Fields
              </Link>
            },
            {
              key: '/admin/websetup/webcontrols',
              label: 'WebControl',
              children: [
                {
                  key: '/admin/websetup/webcontrols/booleans',
                  label:  <Link
                    to={'/admin/websetup/webcontrols/booleans'}>
                    Boolean
                  </Link>
                },
                {
                  key: '/admin/websetup/webcontrols/integers',
                  label:  <Link
                    to={'/admin/websetup/webcontrols/integers'}>
                    Integer
                  </Link>
                },
                {
                  key: '/admin/websetup/webcontrols/strings',
                  label:  <Link
                    to={'/admin/websetup/webcontrols/strings'}>
                    String
                  </Link>
                },
                {
                  key: '/admin/websetup/webcontrols/tiles',
                  label:  <Link
                    to={'/admin/websetup/webcontrols/tiles'}>
                    Tile
                  </Link>
                },
                {
                  key: '/admin/websetup/webcontrols/sites',
                  label:  <Link
                    to={'/admin/websetup/webcontrols/sites'}>
                    Site
                  </Link>
                },
                {
                  key: '/admin/websetup/webcontrols/other',
                  label:  <Link
                    to={'/admin/websetup/webcontrols/other'}>
                    Other
                  </Link>
                },
              ]
            }
          ]
        }
      ]
    },
    {
      key: '/select-catalog',
      icon: <FolderOutlined />,
      label:  <Link
        to={'/select-catalog'}>
        Change Catalog
      </Link>
    }
  ];
}
