import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { Container, FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import SupportInfo from "./SupportInfo";
import ChannelBillingEmail from "./ChannelBillingEmail"
import { getServiceExpirationDate, isExpirationModalConfirmed } from "../redux/selectors";
import { confirmExpirationDateModal } from '../redux/slices/expirationDate.slice'
import {
  checkDateExpired,
  checkDateNearlyExpired,
  checkDateValidity,
} from "../helpers/expirationDate-helpers";

/**
 * Component that assesses the console's SAAS contract expiration date
 * retrieved from the feature flag 'ExpirationDate' and stored in redux.
 * If expiration date is within 14 days, in the past, or invalid, shows a modal alerting
 * the user. Should only appear 1x per session. Lives in AntDefaultLayout.
 * @module ExpirationDateModal
 * @component
 */
const ExpirationDateModal = function ExpirationDateModal(props: any) {

  const dispatch = useDispatch();
  // retrieve service expiration date from feature flags in store
  // if client is past/approaching the end of their service contract, we'll show a modal alerting them
  const expirationDate = useSelector(getServiceExpirationDate)
  // check whether the user has already acknowledged the modal during this session -
  // if so, we won't show it again until a new session: user logs out/logs in again, or refreshes their browser
  const isExpirationConfirmed = useSelector(isExpirationModalConfirmed)

  // hook controlling visibility of expiration alert modal
  const [showModal, setShowModal] = useState(false)

  // check the value of the ExpirationDate feature flag:
  // if date is invalid or missing, modal text will indicate there's a problem with the value
  const isValidDate = checkDateValidity(expirationDate)
  // if date is within 14 days of today, modal text will indicate their service period is nearly over
  const isNearlyExpired = checkDateNearlyExpired(expirationDate)
  // if date is expired, modal text will indicate their service period is over
  const isExpired = checkDateExpired(expirationDate)

  useEffect(() => {
    // if expiration date is invalid or < 14 days from today,
    // AND if user has not yet acknowledged the current expiration date during this session,
    // show the modal
    if (!isExpirationConfirmed && (!isValidDate || isNearlyExpired)) {
        setShowModal(true)
    }
  }, [expirationDate, isExpirationConfirmed])

  return (
    <Modal
      title={'Console Service Agreement'}
      open={showModal}
      maskClosable={false}
      destroyOnClose={true}
      wrapClassName="expiration-date-modal"
      data-testid="expiration-date-modal"
      onCancel={() => {
        dispatch(confirmExpirationDateModal(true))
        setShowModal(false)
      }}
      footer={[
        <Button
          key={'expiration-date-msg'}
          className="btn-secondary"
          data-testid='confirm-expiration-msg'
          color="success"
          onClick={() => {
            dispatch(confirmExpirationDateModal(true))
            setShowModal(false)
          }}
        >Ok</Button>]}
    >
      <Container>
        {!isValidDate && (
          <div>
          <FormGroup>
            Your subscription contract expiration date is invalid.
          </FormGroup>
            <FormGroup>
              To resolve this issue, contact Channel Software support at <SupportInfo/>.
            </FormGroup>
          </div>
        )}
        {isExpired && (
          <div>
            <FormGroup>
              Your subscription contract expired on:
            </FormGroup>
            <FormGroup style={{marginLeft: '1rem'}}>
              {expirationDate}
            </FormGroup>
            <FormGroup>
              Channel Software has mailed a renewal invoice. Please ensure this invoice is paid in full. To receive a copy of this invoice please contact
              <ChannelBillingEmail/>.
            </FormGroup>
          </div>
        )}
        {isValidDate && !isExpired && isNearlyExpired && (
          <div>
            <FormGroup>
              You are nearing the end of your subscription contract, which is set to expire on:
            </FormGroup>
            <FormGroup style={{marginLeft: '1rem'}}>
              {expirationDate}
            </FormGroup>
            <FormGroup>
              Channel Software has mailed a renewal invoice. Please ensure this invoice is paid in full by the renewal date. To receive a copy of this invoice please contact
              <ChannelBillingEmail/>.
            </FormGroup>
          </div>
        )}
      </Container>
    </Modal>
  )
}

export default ExpirationDateModal
