import { createSlice } from '@reduxjs/toolkit';

const catalogDataSlice = createSlice({
  name: 'catalogData',
  initialState: null,
  reducers: {
    setCatalogData(state, action) {
      return action.payload
    }
  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = catalogDataSlice
// Extract and export each action creator by name
export const { setCatalogData } = actions
// Export the reducer, either as a default or named export
export default reducer
