import axios from 'axios';
import reduxStore from '../redux/store';
import { setToken } from '../redux/slices/user.slice';
import config from "../app-config";
import isUrlPermitted from './endpointPermissions'

/**
 * Generates the base API URL to be used in API requests.  For most older clients this value is supplied via
 * a pipeline variable, which in production is embedded in the built index.html and extracted by app-config.
 * For most newer clients the base API URL is by convention an extension of the URL where the console frontend
 * is hosted, so we can use the window location to construct it.
 * @returns {string} - the base URL to be used in API requests
 */
export const getAPIVariable = () => {
  if (config.REACT_APP_API && config.REACT_APP_API.length > 0) return config.REACT_APP_API;
  else return `${window.location.origin}/api`
};

/**
 * Creates an Axios Client with default url to make API requests.
 */
const client = axios.create({
  baseURL: getAPIVariable()
});

/**
 * Initializes a cancellation token that will be called if app gets stuck in a 401 loop.  Sometimes when using a stale token
 * the app will get stuck in a cycle of retrying a denied request with the expired token instead of fetching/using a new
 * token. This rare timing issue is challenging to debug and completely resolve, so we protect against it by issuing a
 * cancellation token used to break the loop if the app encounters > 5 failed reqs due to 401s.
 * @type {CancelTokenStatic}
 */
// const CancelToken = axios.CancelToken;
// const source = CancelToken.source();
const controller = new AbortController()
/**
 * Initializes count for failed 401 requests. If the count reaches 5, cancellation token will be called to exit 401 loop.
 * @type {number}
 */
let failedReqAttempts = 0;

/**
 * Initializes a request wrapper with default success/error actions. This wrapper prepares axios requests for our API
 * by attaching an access token from redux, setting necessary request headers and adding the cancellation token initialized
 * above. Used to implement API endpoints in api.ts.
 * @param {Object} options - request params such as method, request route, and body data
 */
const wrappedRequest = function (options) {
  let accessToken = null;

  const storeData = reduxStore.getState();

  if (storeData.user && storeData.user.accessToken) {
    accessToken = storeData.user.accessToken
  }
  // if we still have a null token value at this point, reject request
  // helps avoid race condition where redux token perpetually loops btwn stale value and null
  if (!accessToken) return Promise.reject({data: 'no token'});

  // check user's permissions to see if they have access to call the endpoint
  // not currently implemented but available if we want to add an additional layer of protection for
  // provisioned/restricted features
  if (!isUrlPermitted(options, storeData)) {
    return Promise.reject({data: `You don't have permission to call the endpoint ${options.method} ${options.url}.`})
  }

  // attach token to wrapped axios request, add cancellation token, set headers
  const optionsWithAuth = {
    ...options,
    // cancelToken: source.token,
    signal: controller.signal,
    headers: {
      ...options.headers,
      Authorization: 'Bearer ' + accessToken,
      Pragma: 'no-cache'
    }
  };

  // called if request returns a success code
  const onSuccess = function(response) {
    const requestPath = optionsWithAuth.url;
    // on a successful response, set the failed 401 count back to 0 -
    // any 401 thrown was likely due to a stale token which has since been refetched
    // however - don't reset the count if the successful request was to a google-analytics endpoint:
    // these are currently unprotected so will always succeed even with an invalid token
    // (may be resolved in SUB2-1617)
    if (!requestPath.includes('google-analytics')) failedReqAttempts = 0;
    // catch malformed responses - responses where the API returns a success code but the payload is malformed
    // in the past this has presented as a string value for response.data (html for the frontend bundle)
    // likely caused by an issue with API implementation/routing
    // won't implement for now - high risk since it affects every xhr request - but we can if we start seeing more malformed issues
    // if (!response.data || typeof response.data !== 'object') throw new Error('malformed response');
    // console.debug('Request Successful!', response);
    // if (response.data && response.data.data) return response.data;
    // sometimes a valid response.data.data value === false, as when checking for part number uniqueness
    // make sure response.data exists, then return response.data.data (if it exists) OR response.data === null (also a potentially valid response)
    if (response.data !== undefined && (response.data === null || response.data.data !== undefined)) return response.data;
    else return response;
  };

  // called if request returns an error code; rejects request Promise
  const onError = function(err) {
    console.error('Request failed:', err.response);
    if (err.response) {
      // Request was made but server responded with something other than 2xx
      console.error('Status:',  err.response.status);
      console.error('Error:',    err.response.data && err.response.data.error ? err.response.data.error : err.response.data);
      console.error('Headers:', err.response.headers);
      if (err.response.status == 401) {
        // user is unauthorized:
        // either they're using a stale access token, or there's an issue with the API
        console.log('received 401');
        // to avoid an infinite loop of retrying requests,
        // increment count for failed requests...
        failedReqAttempts++;
        // ...and if we've reached the limit, issue the req cancellation token
        // to stop the loop from hammering the API
        // if (failedReqAttempts > 5) source.cancel('Cancelled requests - invalid access token');
        if (failedReqAttempts > 5) {
          controller.abort();
          console.log('Cancelled requests - invalid access token')
        }
        // otherwise it was likely a stale token
        // so clear token in store so app.js renews it
        if (accessToken && failedReqAttempts <= 5 ) {
          console.log('nullifying token')
          reduxStore.dispatch(setToken(null));
        }
      }
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', err);
    }
    return Promise.reject(err.response || err.data || err);
  };

  // setup promise chain
  return client(optionsWithAuth)
    .then(onSuccess)
    .catch(onError)
};

export default wrappedRequest;
