import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useErrorHandler } from 'react-error-boundary';
import useAxiosRequest from '../../hooks/useAxiosRequest';
import { fetchCatalogs } from '../../data/api';
import Loading from '../../components/Loading';
import { CustomErrorMsg } from "../../components/CustomErrorMsgRHF";
import { SelectDropdownOption } from "../../models/Product";


/**
 * Sorts and transforms fetched catalog data for consumption by Select component.
 * @param {string[]} catalogList - a list of catalogs fetched from the API
 * @returns {SelectDropdownOption[]} - a list of catalogs, sorted alphabetically, with testIds,
 * structured to be used as options by the Select component
 * @memberof module:CatalogSelect
 * @inner
 */
const prepareCatalogData = (catalogList: string[]) => {
  const sortedList = catalogList.sort((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase())
  });
  return sortedList.map(catalog => ({label: catalog, value: catalog, 'data-testid':`catalog-${catalog}`}))
}

/**
 * Component to render a sorted list of catalogs from the API in a searchable, multi- or single-select dropdown.
 * Used in two different contexts:
 * - on forms using RHF validation
 * -- allows users to associate catalog(s) with an entity record (Login, Warehouse or Login Budget)
 * -- has access to validation props to enter catalog(s) into state
 * - OR on the SelectCatalogView
 * -- allows users to set their current catalog used when navigating around the console and making API requests
 * -- uses standard hook getters/setters to enter catalog into state
 *
 * To implement in the RHF context, CatalogSelect is wrapped by CatalogSelectWrapper, which translates
 * RHF's onChange into the handleChange prop and RHF's value[fieldName] into the selectedCatalog prop.
 *
 * @param props
 * @module CatalogSelect
 * @component
 */
const CatalogSelect = function CatalogSelect(props: any) {
  const {
    control,
    fieldName,
    allowClear,
    multiSelect,
    placeholder,
    selectedCatalog,
    handleChange,
    onBlur,
    setCatalogsError
  } = props;

  // custom hook used to request catalog data and put it in state
  const [catalogs, catalogsError] = useAxiosRequest(() => fetchCatalogs());
  // hook holding the sorted catalog data used to populate dropdown list
  const [sortedList, setSortedList] = useState<SelectDropdownOption[] | undefined>(undefined);
  // if an error occurs on fetching catalogs, assess whether to populate useErrorHandler or use the setCatalogsError hook:
  // if CatalogSelect is being used in a RHF context, setCatalogsError will be undefined and useErrorHandler will be populated.
  // if CatalogSelect is being used in the SelectCatalogView, the error must be set in the parent's state
  // so that the UI can be displayed correctly in this top-level error case
  useErrorHandler(setCatalogsError ? null : catalogsError);

  // if CatalogSelect is being used in the SelectCatalogView, useErrorHandler will not be populated,
  // so this effect will run to set the error in the parent's state.  The error will limit the actions
  // the user can take on the SelectCatalogView and will display a more complex error message
  useEffect(() => {
    if (catalogsError) setCatalogsError(true)
  }, [catalogsError])

  // When data is fetched, sort/transform it for consumption by Select component
  useEffect(() => {
    if (catalogs) setSortedList(prepareCatalogData(catalogs))
  }, [catalogs]);

  if (!sortedList) return <Loading />;

  return (
    <React.Fragment>
      <span id='catalog-picker-wrapper'>
      <Select
        getPopupContainer={() => document.getElementById('catalog-picker-wrapper') || document.body}
        data-testid='catalog-picker'
        mode={multiSelect ? 'multiple' : undefined}
        placeholder={placeholder ? placeholder : `Select catalog${multiSelect ? 's' : ''}`}
        showSearch={true}
        optionFilterProp={'label'}
        defaultValue={selectedCatalog}
        value={selectedCatalog}
        allowClear={allowClear ? allowClear : false}
        onChange={value => {
          // in RHF context, handleChange prop will call onChange(value)
          // in SelectCatalogView, handleChange prop will call setSelectedCatalog hook
          handleChange(value)
        }}
        onBlur={() => {
          // in RHF context, onBlur prop will call onBlur(true)
          // in SelectCatalogView, no onBlur prop is passed
          if (onBlur) onBlur()
        }}
        options={sortedList} />
        {/* only show CustomErrorMsg in RHF context - props.control is not passed in SelectCatalogView */}
        {/* CustomErrorMsg displays errors when validating via a Yup schema within RHF implementation,
         such as 'required' errors. Not needed in SelectCatalogView, where error handling is managed via useState/useEffect */}
        {!!control && (
          <CustomErrorMsg id={`${fieldName}-error`} name={`${fieldName}`} control={control}/>
        )}
        </span>
    </React.Fragment>
  )
};

export default CatalogSelect
