import React from 'react';

export default function SupportInfo () {
  return (
    <React.Fragment>
      <a href="mailto:support@channelsoftware.com"> support@channelsoftware.com </a>
      or (888)786-3487
    </React.Fragment>
  )
}
