import { AntSidebarConfigItem, FeatureDecisions } from "../models/App";

/**
 * Function that returns a navbar config to be used in the App's sidebar.  Adds links to the sidebar config
 * depending on various feature flag values.
 * @param {AntSidebarConfigItem} navConfig - an object representing the sidebar with all links exposed
 * @param {FeatureDecisions} featureFlags - an object containing feature flag hooks
 */
export const limitAntNavTree = (navConfig: AntSidebarConfigItem[], featureFlags: FeatureDecisions) => {
  // instantiate a variable to build up the filtered sidebar items
  let filteredSidebar = [] as AntSidebarConfigItem[]
  // loop over the full list of sidebar items
  navConfig.forEach(sidebarItem => {
    filteredSidebar = filteredSidebar.concat(addSidebarItemToAntNavTree(sidebarItem, featureFlags))
  })
  return filteredSidebar
}

/**
 * Recursive function to traverse a branch of the navbar config tree and add links based on various feature flag values.
 * @param {AntSidebarConfigItem} sidebarItem - the branch of the sidebar tree being traversed
 * @param {FeatureDecisions} featureFlags - an object containing feature flag hooks
 */
const addSidebarItemToAntNavTree = (sidebarItem: AntSidebarConfigItem, featureFlags: FeatureDecisions) => {
  const filteredSidebar = [] as AntSidebarConfigItem[]
  let addSidebarItem = false
  // assess value of addSidebarItem
  if (sidebarItem.flagName) {
    // if the sidebarItem has a limiting feature flag, check the flag's value in state
    // to determine whether the item should be included in the sidebar
    addSidebarItem = featureFlags[sidebarItem.flagName]()
  }
    // otherwise the sidebarItem has no limiting feature flag,
  // and is always included in the sidebar
  else addSidebarItem = true
  // add the sidebarItem if it should be included
  // don't add children initially b/c their flags must be assessed in a similar way as above
  if (addSidebarItem) {
    const {children, flagName, ...rest} = sidebarItem
    filteredSidebar.push(rest)
    let lastItemIndex = filteredSidebar.length - 1
    if (children) {
      filteredSidebar[lastItemIndex].children = sidebarItem.children!.reduce((children, childItem) => {
        //@ts-ignore
        return children.concat(addSidebarItemToAntNavTree(childItem, featureFlags))
      }, [])
    }
  }
  return filteredSidebar
}

/**
 * Given the current pathname, finds the matching Route from the app's list of routes, then splits that Route's path into segments.
 * Uses segments to locate the correct nav item in the sidebarConfig tree so it can be set in state as the current selected key
 * and indicated as such in the sidebar UI.  Along the way, keeps track of each intermediate nav item so they can be set
 * in state as open keys and shown as expanded submenus in the sidebar UI.
 *
 * @param {string} path - the path of the current route, provided by react-router's useLocation hook
 * @param {AntSidebarConfigItem[]} sidebarConfig - an array representing the sidebar tree with all links exposed
 * @returns {{selectedKeys: string[], openKeys: string[]}} - an object listing the currently-selected key and the intermediate
 * expanded keys in the sidebar, used to set initial values for hooks in the Sidebar component. Provides visual indicator to user
 * of the current location in the app.
 */
export const initializeRoute = (path: string, sidebarConfig: AntSidebarConfigItem[]) => {
  if (path) {
      // split the current route's path into segments like ['', 'user-maintenance', 'logins', 'update', ':id']
      const segments = path.split('/')
      // ensure we have more than one segment - eg that we're not at 'baseUrl/'
      if (segments.length > 1) {
        // find the top-level sidebar item with a key corresponding to segment[1] eg 'user-maintenance'
        // sidebar item keys are provided as partial urls, so look for '/user-maintenance'
        const topRoute = sidebarConfig.find(sidebarItem => sidebarItem.key === `/${segments[1]}`)
        if (topRoute) {
          // keep a record of each sidebar item we locate.  Their keys will be used to set
          // the open keys so the correct submenus are expanded for a given route in the sidebar UI.
          const segmentKeys = [topRoute.key]
          // begin at index 2, since segment[0] is '' and we've already located the sidebar item corresponding to segment[1]
          let segmentIndex = 2
          // initialize nextSegment to start with the prior sidebar item's key, eg '/user-maintenance'
          let nextSegment = `/${segments[1]}`
          // prepare to search the children of the last found sidebar item for the next segment
          let nextRoute: AntSidebarConfigItem | undefined = topRoute
          do {
            // append the next segment in segments[] so it becomes '/user-maintenance/logins'
            nextSegment += `/${segments[segmentIndex]}`
            // search children of the last found sidebar item to find the item with key corresponding to '/user-maintenance/logins'
            nextRoute = nextRoute?.children?.find((childSidebarItem: {key: string}) => childSidebarItem.key === nextSegment)
            // if we found a matching child, add its key to the tracked hierarchy of sidebar item keys
            if (nextRoute) {
              segmentKeys.push(nextRoute.key)
              segmentIndex +=1
            }
            // if nextRoute is undefined, we've reached a leaf node on the sidebarConfig tree and should have identified each sidebar
            // item in the hierarchy for the current route
          } while (nextRoute)
          // return the final key in segmentKeys, which will be set in state as the currently-selected sidebar item,
          // and return the full hierarchy of segmentKeys, which will be set in state as the open submenu items terminating in the selected item
          return {
            selectedKeys: [segmentKeys[segmentKeys.length - 1]],
            openKeys: segmentKeys
          }
        }
      }
    }
  return {
    selectedKeys: [],
    openKeys: []
  }
}
