import { createAction } from '@reduxjs/toolkit';
export const UPDATE_FEATURE_FLAG = 'UPDATE_FEATURE_FLAG'
export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS'

// used to update a specific flag's boolean after updating via PATCH /feature/{featureName}
// this action defined here, instead of in featureFlags.slice, because multiple slices' reducers must
// respond to this action (analytics, featureFlags, expirationDate)
// defining it here, in a separate file, avoids circular reference issues
export const updateFeatureFlag = createAction(UPDATE_FEATURE_FLAG)

// old implementation
// // used to update a specific flag's boolean after updating via PATCH /feature/{featureName}
// export const updateFeatureFlag = (featureFlagData) => ({
//   type: UPDATE_FEATURE_FLAG,
//   payload: featureFlagData
// })
//
// // used to initialize all feature flags in redux after calling GET /features
// // called in App.tsx
// export const setFeatureFlags = (featureFlagsData) => ({
//   type: SET_FEATURE_FLAGS,
//   payload: featureFlagsData
// })

