import { LogLevel } from "@azure/msal-browser";
import config from "./app-config";

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_susi_v2',
    forgotPassword: 'B2C_1_reset_v3',
    editProfile: 'B2C_1_edit_profile_v2',
  },
  authorities: {
    signUpSignIn: {
      authority: `https://login.microsoftonline.com/${config.REACT_APP_AZURE_TENANTID}`,
    },
    forgotPassword: {
      authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_reset_v3',
    },
    editProfile: {
      authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2',
    },
  },
  authorityDomain: 'login.microsoftonline.com',
};


/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: config.REACT_APP_AZURE_CLIENTID, // This is the ONLY mandatory field that you need to supply.
    authority: `https://login.microsoftonline.com/${config.REACT_APP_AZURE_TENANTID}`, // Choose SUSI as your default authority.
    knownAuthorities: [], // Mark your B2C tenant's domain as trusted.
    redirectUri: `/signin-oidc`, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: '/login', // Indicates the page to navigate after logout.
    // If "true", will navigate back to the original request location before processing the auth code response.
    // allows the user to be returned to the page where the auth request was initiated,
    // instead of being returned to the catch-all "Dashboard" route
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    // provides console logging for MSAL actions
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */

// we don't currently use scopes this fine - every user has the scope View.All
// for their client's API. Scopes passed on login and on token refresh are identical.
// this may change in the future.  For now, see loginScopes below

// export const protectedResources = {
//   apiTodoList: {
//     endpoint: 'http://localhost:5000/api/todolist',
//     scopes: {
//       read: ['https://fabrikamb2c.onmicrosoft.com/TodoList/ToDoList.Read'],
//       write: ['https://fabrikamb2c.onmicrosoft.com/TodoList/ToDoList.ReadWrite'],
//     },
//   },
// };

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginScopes = {
  scopes: [`api://${config.REACT_APP_AZURE_API_CLIENTID}/View.All`]
};
