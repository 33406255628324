import React, { useEffect } from "react";
import { Route, RouteProps } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { RouteComponentProps } from "react-router";
import config from "../app-config";
import { loginScopes } from "../authConfig";

// because we pass props like catalog to each Route's Component,
// we have to modify auth0's PrivateRoute to pass those props through

const PrivateRoute = ({ component: Component, path, ...rest }:
                        RouteProps) => {
  const { isAuthenticated: isAuthenticatedAuth0, loginWithRedirect } = useAuth0();
  const { instance, accounts } = useMsal();
  const isAuthenticatedAzure = useIsAuthenticated();
  console.log('a0', isAuthenticatedAuth0);
  console.log('az', isAuthenticatedAzure)
  useEffect(() => {
    const fn = async () => {
      if ((config.REACT_APP_AZURE_AUTH === 'true') && !isAuthenticatedAzure && rest.location) {
        console.log('reached azure redirect')
        await instance.loginRedirect({
          account: accounts[0],
          scopes: loginScopes.scopes
        })
      }
      else if (config.REACT_APP_AZURE_AUTH !== 'true' && !isAuthenticatedAuth0 && rest.location) {
        console.log('reached auth0 redirect')
        console.log(rest.location.pathname)
        await loginWithRedirect({
          appState: { targetUrl: rest.location.pathname }
        });
      }
    };
    fn();
}, [isAuthenticatedAzure, isAuthenticatedAuth0, path]);

  const render = (props: RouteComponentProps) => {
    // check authentication
    // only return component if authenticated
    return (isAuthenticatedAzure || isAuthenticatedAuth0) && Component ? <Component {...props}  /> : null;
  };
// render prop consuming UI component
  return <Route path={path} exact={false} render={render} {...rest} />;
};

export default PrivateRoute;
