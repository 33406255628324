import { createFeatureDecisionsFromStore } from './featureDecisions'
import { ProtectedEndpoint, ReqOptions, StoreData } from "../models/App";

// When user makes an API call, check their permissions in the redux store to
// ensure they have the access to call the endpoint.
// A user should never be able to reach this state if UI permissions are working correctly,
// but this adds an extra layer of auth protection.

/**
 * A list of protected endpoints, including the specific feature decision that determines
 * whether the user has permission to call the endpoint.
 */
const permissionMap: ProtectedEndpoint[] = [
  // {
  //   method: 'GET',
  //   url: `/content-groups`,
  //   includeFeature: (features: FeatureDecisions) => features.includeWriteSites()
  //   // permission: 'write:sites'
  // }
];

/**
 * Function that checks whether user has the correct permissions to call an endpoint.
 * Called by authAxiosRequest.
 * @param {ReqOptions} reqOptions - Options for the request currently being made/checked.
 * Used to determine whether the request is being made to a protected endpoint.
 * @param {StoreData} store - current state of data in redux store
 * @returns {boolean} - boolean representing user's permission to call the endpoint
 */
const isUrlPermitted = (reqOptions: ReqOptions, store: StoreData) => {
  const catalog = store.catalog;
  // first find the correct endpoint in the array of protected endpoints
  const foundEndpoint = permissionMap.find(endpointObj => {
    return ((endpointObj.url.replace(':catalog', catalog) === reqOptions.url) && (endpointObj.method === reqOptions.method))
  });

  if (!foundEndpoint) return true;

  // then generate the feature decisions based on the current store values
  const featureDecisions = createFeatureDecisionsFromStore(store);
  // return permission boolean
  return foundEndpoint.includeFeature(featureDecisions)
};

export default isUrlPermitted
