import React from 'react'
import { Helmet } from 'react-helmet';
import { AppRoute } from "../models/App";

const PageTitle = function PageTitle({ activeRoute }: { activeRoute: AppRoute  }) {

  return (
    <Helmet>
      <title>{activeRoute && activeRoute.name ? activeRoute.name : 'CSX Console'}</title>
    </Helmet>
  )
};

export default PageTitle
