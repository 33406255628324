import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Label, Button } from 'reactstrap';
import { Layout } from 'antd';
import { ErrorBoundary } from "react-error-boundary";
import { useQueryClient } from "react-query";
import { getCatalog } from '../../../redux/selectors';
import Loading from '../../../components/Loading';
import { fetchCatalog } from '../../../data/api';
import { setCatalog } from '../../../redux/slices/catalog.slice';
import { setCatalogData } from '../../../redux/slices/catalogData.slice';
import { setAnalytics } from '../../../redux/slices/analytics.slice';
import { setFeatureFlags } from "../../../redux/slices/featureFlags.slice";
import { CatalogData } from "../../../models/App";
import SupportInfo from "../../../components/SupportInfo";
import DefaultHeader from "../../../containers/DefaultLayout/DefaultHeader";
import CatalogSelect from '../../Logins/CatalogSelect'
import ErrorFallback from "../../../components/ErrorFallback";


const { Header } = Layout;

/**
 * Special UI component allowing user to update the currently-selected catalog from a list fetched
 * from the API.
 * @module SelectCatalogView
 * @component
 */
export const SelectCatalogView = function SelectCatalogView(props: any) {
  const dispatch = useDispatch();
  const catalog = useSelector(getCatalog);
  const queryClient = useQueryClient()
  // hook holding the name of the catalog currently selected from the dropdown list
  // instantiated based on current catalog in redux, if any
  const [selectedCatalog, setSelectedCatalog] = useState<string | undefined>(catalog ? catalog : undefined);
  // hook holding the data associated with the currently-selected catalog
  const [selectedCatalogData, setSelectedCatalogData] = useState<CatalogData | null>(null);
  // hook holding the current error state from the catalogs list fetch call.  Used to show/hide
  // CatalogSelectUI, or an error message if fetch failed
  const [catalogsError, setCatalogsError] = useState<boolean>(false)
  // hook holding the redirect flag; used to route the user to the dashboard after
  // confirming their catalog selection
  const [redirect, setRedirect] = useState<boolean>(false);
  // hook indicating whether the fetch for an individual catalog's data is underway.
  // disables 'confirm' button and indicates API activity to user
  const [fetching, setFetching] = useState<boolean>(false);
  // hook holding the current error state from the individual catalog data fetch call.
  // disables 'confirm' button and shows error message if true.
  const [catalogError, setCatalogError] = useState<boolean>(false);

  // when user selects a catalog from the list, fetch data for that catalog
  // if catalog fetch errors, set the error hook so error text can be shown
  useEffect(() => {
    if (selectedCatalog) {
      setFetching(true);
      setCatalogError(false);
      fetchCatalog(selectedCatalog)
        .then(res => {
          setSelectedCatalogData(res.data);
          setFetching(false)
        })
        .catch(err => {
          setFetching(false);
          setCatalogError(true)
        })
    }
  }, [selectedCatalog]);

  const handleChange = (event: string) => {
    setSelectedCatalog(event)
  };

  // when user clicks 'confirm,' set catalog and catalogData in redux,
  // set feature flags to null to re-fetch them for the new catalog,
  // set analytics flag to null to re-fetch config,
  // and set catalog name in local storage
  // then redirect to dashboard
  const confirm = () => {
    if (selectedCatalog) {
      // set the selected catalog and its data in redux
      dispatch(setCatalog(selectedCatalog));
      dispatch(setCatalogData(selectedCatalogData));
      // set feature flags to null so App.tsx will re-fetch them for the new catalog
      dispatch(setFeatureFlags(null))
      // set GA flag to null so App.tsx will re-fetch analytics config
      // this fixes the issue where client must manually go to
      // GA settings page to trigger analytics on dashboard after catalog change
      dispatch(setAnalytics(null));
      // cache the selected catalog. this will allow most returning users to avoid selecting
      // a catalog when logging in again.
      localStorage.setItem('catalog', selectedCatalog);
      // clear out any cached data queries so that no queries from a stale catalog will exist in
      // the queryClient.  Avoids inadvertent refetching of non-relevant queries.
      // This can be optimized if needed; see SUB2-7889
      queryClient.removeQueries()
      // finally, redirect to the dashboard
      setRedirect(true)
    }
  };

  // when redirect flag is true, redirect to dashboard
  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <div className="app">
      <Layout>
        <Header className="app-header navbar">
          <DefaultHeader children={props.children} hideCatalog={true}/>
        </Header>
        <Layout className="catalog-container animated fadeIn">
          <div className="inner-catalog-container">
            {/* if catalogs fetch errors, no recovery is possible b/c a catalog is needed to access the app */}
            {/* show error text instructing user to contact support */}
            {catalogsError && (<p className="form-validation">An error occurred when fetching catalogs. Contact Channel Software support at <SupportInfo/>.</p>)}
            {/* else, if catalogs fetch succeeds, show catalog picker UI */}
            {!catalogsError && (
              <React.Fragment>
                <Label for="select-catalog-picker">Select a catalog:</Label>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                <CatalogSelect
                  allowClear={false}
                  multiSelect={false}
                  selectedCatalog={selectedCatalog}
                  handleChange={handleChange}
                  setCatalogsError={setCatalogsError}
                />
                </ErrorBoundary>
                {/* disable button if catalog data is loading or has errored */}
                <Button
                  className="confirm-catalog btn-secondary"
                  onClick={confirm}
                  disabled={fetching || catalogError || !selectedCatalog}>
                  Confirm
                </Button>
                {/* if catalog data fetch errors, show error text instructing user to contact support */}
                {/* but continue to show catalog picker in case another catalog IS retrievable */}
                {catalogError && (
                  <p className="form-validation">An error occurred when fetching this catalog. Contact Channel Software support at <SupportInfo/>.</p>
                )}
                {/* if catalog data fetch is in process, show Loader for user cue that something is being fetched */}
                {fetching && (
                  <Loading />
                )}
              </React.Fragment>
            )}
          </div>
        </Layout>
      </Layout>
    </div>
  );
};

export default SelectCatalogView
