import { createSlice } from '@reduxjs/toolkit';
import {
  UPDATE_FEATURE_FLAG
} from '../actions/featureFlags.action';


const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  // don't start with an empty object, or an object w/ featureName keys -
// initial state must be falsy in order to trigger initial fetch
// of featureFlag data in App.tsx
  initialState: null,
  // used to initialize all feature flags in redux after calling GET /features; called in App.tsx
  // we could leave the action in featureFlags.action and add it here in featureFlagsSlice.extraReducers,
  // but putting it here avoids a TS error when the reducer is called in the fetchFeatureFlags error case in App.tsx
  reducers: {
    setFeatureFlags(state, action) {
      let featureFlagsState = {}
      // handle case where feature flags are cleared, such as when selecting a new catalog
      if (action.payload === null) featureFlagsState = null
      else {
        action.payload.forEach((flagData) => {
          featureFlagsState[flagData.featureName] = {
            enabled: flagData.featureEnabled,
            value: flagData.featureValue
          }
        })
      }
      return featureFlagsState
    }
  },
  // allow this slice to respond to action types it has not generated via the reducers key:
  // because analytics.slice must also respond to the UPDATE_FEATURE_FLAG action,
  // the featureFlag actions specified here are generated in featureFlags.action
  // instead of in featureFlags.slice.reducers to avoid circular reference issues
  extraReducers: (builder) => {
    builder
      .addCase(UPDATE_FEATURE_FLAG, (state, action) => {
        const featureFlagData = action.payload;
        state[featureFlagData.featureName] = {
          enabled: featureFlagData.featureEnabled,
          value: featureFlagData.featureValue
        }
      })
  }
})

// Extract the actions and reducer
const { actions, reducer } = featureFlagsSlice
// Extract and export each action creator by name
export const { setFeatureFlags } = actions
// Export the reducer, either as a default or named export
export default reducer
