import React from 'react';
import moment from 'moment';

export default function DefaultFooter() {

  const currentYear = moment().year();

  return (
    <React.Fragment>
      <span style={{fontSize: '11px'}}>Channel Software &copy; {currentYear}</span>
    </React.Fragment>
  );
};


