import { LicenseManager } from '@ag-grid-enterprise/core';
import {
  ClientSideRowModelModule,
  CsvExportModule,
  InfiniteRowModelModule,
  ModuleRegistry
} from '@ag-grid-community/all-modules';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'

/**
 * Reusable function that initializes ag-grid.  Provides license key to register enterprise version,
 * and registers modules for the functionality we use.  Called in index.js and unit test renderers.
 */
export default function initializeAgGrid () {
  // per their docs, embedding the key in the frontend code is acceptable
  LicenseManager.setLicenseKey("CompanyName=Channel Software,LicensedApplication=CSX Console,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=-1,AssetReference=AG-028652,SupportServicesEnd=23_June_2023_[v2]_MTY4NzQ3NDgwMDAwMA==44b81140c61389cee9ddc7e090145160");

  // globally register ag-grid modules here, before any grid is instantiated.
// modularization lets us import a fraction of the ag-grid-enterprise library,
// reducing bundle size by 0.7 MB.
// registering modules globally prevents us from having to register them one-by-one
// on each grid instance.
  ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    InfiniteRowModelModule,
    RowGroupingModule,
    MasterDetailModule,
    SideBarModule,
    FiltersToolPanelModule,
    CsvExportModule,
    ExcelExportModule,
    ServerSideRowModelModule
  ])
}
