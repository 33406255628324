import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { Breadcrumb, Layout } from 'antd';
import Sidebar from './Sidebar'
import HelpInfoModal from '../../components/HelpInfoModal';
import PageTitle from '../../components/PageTitle'
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import ExpirationDateModal from "../../components/ExpirationDateModal";

const { Header, Content, Footer } = Layout;

/**
 * App's layout implemented using antd components; replaces deprecated CoreUI layout. Renders fixed
 * header/footer, a sidebar permitting navigation, and the current route's components.
 * @module AntDefaultLayout
 * @component
 */
const AntDefaultLayout = function AntDefaultLayout(props: any) {
  const {
    activeRoute,
    children
  } = props;

  // hook controlling the boolean collapsed/expanded state of the sidebar
  // 'collapsed' state can indicate either a fully collapsed sidebar OR a minimized sidebar -
  // the antd sidebar considers these both 'collapsed'
  const [collapsed, setCollapsed] = useState(false);

  // the sidebar's current collapsed state - 'full', 'minimized', or 'zero'
  // instantiate based on viewport size to avoid flickering of sidebar when this component is rendered
  // breakpoint for collapse is 'lg'
  const [collapseType, setCollapseType] = useState(window.innerWidth <=991 ? 'zero' : 'full');
  // the sidebar's current width, whether hidden or showing - 'full' or 'minimized'
  // will be correctly initialized by the component when first rendered
  // stores sidebar width when collapsed state changes
  const [sidebarWidth, setSidebarWidth] = useState('')

  return (
    <div className={`app header-fixed sidebar-fixed
    ${collapseType === 'zero' ? '' : 'sidebar-show'}
    ${collapseType === 'minimized' ? 'sidebar-minimized sidebar-lg-show' : ''}
    ${collapseType === 'full' ? 'sidebar-lg-show' : ''}`}>
      <Layout>
        <Header className="app-header navbar">
          <DefaultHeader collapsed={collapsed} setCollapsed={setCollapsed}
                         collapseType={collapseType} setCollapseType={setCollapseType}
                         sidebarWidth={sidebarWidth} setSidebarWidth={setSidebarWidth}/>
        </Header>
        <Layout className="app-body">
          <Sidebar collapsed={collapsed} setCollapsed={setCollapsed}
          collapseType={collapseType} setCollapseType={setCollapseType}
                   sidebarWidth={sidebarWidth} setSidebarWidth={setSidebarWidth}/>
          <Layout>
              <Content className="main">
                <div className="d-flex justify-content-between">
                  <PageTitle activeRoute={activeRoute}/>
                  <Breadcrumb className={'breadcrumb'}>
                    <Breadcrumb.Item className="breadcrumb-item active">{activeRoute?.name ? activeRoute.name : ''}</Breadcrumb.Item>
                  </Breadcrumb>
                  <HelpInfoModal activeRoute={activeRoute}/>
                </div>
                <Container fluid className="main__content">
                  {/* HOC wraps the component currently being rendered based on route - see App.tsx */}
                  {children}
                </Container>
              </Content>
          </Layout>
        </Layout>
        <Footer className="app-footer">
          <DefaultFooter/>
        </Footer>
      </Layout>
      <ExpirationDateModal/>
    </div>
  )
}

export default AntDefaultLayout
