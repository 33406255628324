import React from 'react';
import { Button } from 'antd';
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';
import { useAuth0 } from "@auth0/auth0-react";
import { useMsal } from "@azure/msal-react";
import config from "../../../app-config";
import { loginScopes } from "../../../authConfig";

export default function Login() {

  const { loginWithRedirect } = useAuth0();
  const { instance } = useMsal();

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" md="6" >
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Row>
                    <img
                      src={config.REACT_APP_COMPANY_LOGO}
                      alt="client logo"
                      className="client_logo-img"
                      style={{ maxHeight: '100%', maxWidth: '100%', paddingBottom: "5px" }}
                    />
                  </Row>
                  <h1>Login</h1>
                  <p className="text-muted">Sign In to the Admin Console</p>
                  <Row>
                    <Col xs="6">
                      <Button
                        className="btn-login btn-success px-4"
                        onClick={() => {
                          if (config.REACT_APP_AZURE_AUTH === 'true') {
                            // after successful auth, AAD routes user to the provided redirectUri
                            // in authConfig.js so the auth code can be exchanged for an access token.
                            // after token is obtained, send user to the redirectStartPage below
                            // instead of back to /login, where they will be 'stuck' w/ no navigation UI.
                            instance.loginRedirect({
                              redirectStartPage: '/dashboard',
                              scopes: loginScopes.scopes
                            })
                          }
                          else if (config.REACT_APP_AZURE_AUTH !== 'true') {
                            loginWithRedirect({})
                          }
                        }}>Login</Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
