import React from 'react';
import { ErrorMessage } from "@hookform/error-message"
import { useFormState } from "react-hook-form";
import { RHFErrMsgProps } from "../models/App";

/**
 * Custom implementation of react-hook-form's ErrorMessage to improve accessibility.
 * Allows us to pass an ID to the rendered element displaying the error message.
 * This ID can be referenced by the companion Input's aria-describedby attribute.
 * @param {RHFErrMsgProps} props - incoming props
 * @param {Control} props.control - the form errors object managed by RHF
 * @param {string} props.id - a unique ID on the rendered error message.  used in the companion Input's aria-describedby attr
 * @param {string} props.name - the field name containing the error message to render
 * @param {string} props.className - optional prop adding add'l classes to the rendered error
 * @component
 */
export function CustomErrorMsg({control, id, name, className}: RHFErrMsgProps) {
  // extract errors obj from useFormState -
  // isolates re-renders at this component level instead of parent form components
  const {errors} = useFormState({control})

  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({message}) => <div id={id} className={`form-validation input-feedback ${className ?? ""}`}>{message}</div>}
    />
  )
}
