import { combineReducers } from '@reduxjs/toolkit';
import catalog from './catalog.slice';
import catalogData from './catalogData.slice';
import user from './user.slice';
import analytics from './analytics.slice';
import ids from './ids.slice'
import featureFlags from './featureFlags.slice'
import expirationDate from './expirationDate.slice'

export default combineReducers({
  catalog,
  catalogData,
  user,
  analytics,
  ids,
  featureFlags,
  expirationDate
});
