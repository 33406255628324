import { createSlice } from '@reduxjs/toolkit';

const idsSlice = createSlice({
  name: 'ids',
  initialState: {ids: [], paginationParams: {}},
  reducers: {
    addIds(state, action) {
      state.ids.push(...action.payload)
    },
    clearIds(state) {
      state.ids = []
    },
    // clearIds: {
    //   reducer: (state, action) => {
    //     state.ids = action.payload
    //   },
    //   prepare: () => {
    //     return { payload: []}
    //   }
    // },
    setNextPageParams(state, action) {
      state.paginationParams = action.payload
    }
  }
})


// Extract the action creators object and the reducer
const { actions, reducer } = idsSlice
// Extract and export each action creator by name
export const { addIds, clearIds, setNextPageParams } = actions
// Export the reducer, either as a default or named export
export default reducer
