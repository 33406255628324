import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import useFeature from '../../hooks/useFeature';
import { AntSidebarConfigItem, FeatureDecisions } from "../../models/App";
import { limitAntNavTree, initializeRoute } from "../../helpers/sidebar-helpers";
import antSidebarConfig from "../../_nav";

const { Sider } = Layout;

/**
 * App's sidebar implemented using antd components; replaces deprecated CoreUI sidebar. Limits the sidebar
 * links shown to users per featureFlag values in redux.
 * @module Sidebar
 * @component
 */
const Sidebar = function Sidebar (props: any) {
  const {
    collapsed, setCollapsed,
    setCollapseType,
    sidebarWidth, setSidebarWidth
  } = props
  // get the path for the current route so we can split it up to locate the corresponding hierarchy of sidebar items
  // allows us to correctly calculate the initial selected and open key(s)
  const { path } = useRouteMatch()
  // get a snapshot of the current feature flags from redux so we can hide sidebar items if needed
  const featureFlags = useFeature((features: FeatureDecisions) => features);
  // keep track of the sidebar config so we can update it if feature flags change
  // initialize the hook to use a config based on the current feature flag values
  const [limitedSidebar, setLimitedSidebar] = useState<AntSidebarConfigItem[]>(limitAntNavTree(antSidebarConfig(), featureFlags))
  // calculate the initial selected and open key(s) based on the current path
  const initialKeys = initializeRoute(path, antSidebarConfig())
  // initialize the selected key based on the current path
  const [selectedKeys, setSelectedKeys] = useState<string[]>(initialKeys.selectedKeys)
  // initialize the open keys. avoids collapsing sidebar submenus each time a new sidebar item is selected
  const [openKeys, setOpenKeys] = useState<string[]>(initialKeys.openKeys)
  // a copy of the current openKeys. used to re-initialize openKeys
  // per the user's current path when sidebar is expanded from a collapsed state
  const [savedOpenKeys, setSavedOpenKeys] = useState<string[]>(initialKeys.openKeys)

  // if feature flag values in redux change, recalculate the visible sidebar items
  useEffect(() => {
    if (featureFlags) {
      setLimitedSidebar(limitAntNavTree(antSidebarConfig(), featureFlags))
    }
  }, [featureFlags])

  // on sidebar collapse, openKeys is set to [] in onOpenChange
  // when re-opening sidebar from a collapsed state, use savedOpenKeys to re-initialize openKeys
  // per the user's current path
  useEffect(() => {
    if (!collapsed) setOpenKeys(savedOpenKeys)
  }, [collapsed])

  return (
    <Sider
      // trigger={null}
      className={'sidebar'}
      collapsible={true} collapsed={collapsed}
      onBreakpoint={broken => {
        // when collapsing via breakpoint,
        if (broken) {
          // collapse menu
          setCollapsed(true)
          // indicate full collapse
          setCollapseType('zero')
        }
        // if expanding via breakpoint,
        else {
          // if sider was previously minimized,
          if (sidebarWidth === 'minimized') {
            // maintain setCollapsed(true)
            // and indicate sider is still minimized
            setCollapseType('minimized')
          }
          // if sider was previously full-width,
          else {
            // expand collapsed state
            setCollapsed(false)
            // and update collapsed state/sidebar width
            setCollapseType('full')
            setSidebarWidth('full')
          }
        }
      }}
      onCollapse={(collapsing, type) => {
        // type can be 'responsive' or 'clickTrigger'
        // only act if collapsing via sider button (type = 'clickTrigger')
        // type = 'responsive' is handled in onBreakpoint above
        if (type === 'clickTrigger') {
          // if collapsing via sider button,
          if (collapsing) {
            // collapse menu
            setCollapsed(true)
            // indicate partial collapsed state
            setCollapseType('minimized')
            // set sidebar width correctly
            setSidebarWidth('minimized')
          }
          // if expanding via sider button,
          else {
            // expand collapsed state
            setCollapsed(false)
            // and update collapsed state/sidebar width
            setCollapseType('full')
            setSidebarWidth('full')
          }

        }
      }}
      breakpoint={"lg"}
      collapsedWidth={50}
    >
      <Menu
        mode="inline"
        // 'pre-render' submenus; renders them before their parents are expanded
        // makes UpdateFlagSidebarLinks unit tests easier (removes need for add'l click events)
        forceSubMenuRender={true}
        items={limitedSidebar}
        theme={"light"}
        selectedKeys={selectedKeys}
        defaultOpenKeys={openKeys}
        openKeys={openKeys}
        // called when submenus open/close, AND when sidebar is hidden
        // when the sidebar being hidden, updatedOpenKeys will be []
        // so user's current location will not be expanded by default when sidebar is re-opened -
        // we work around this with savedOpenKeys and useEffect
        onOpenChange={updatedOpenKeys => {
          // when fxn is called, make a record of the current state of open menus
          // if sidebar is hidden, savedOpenKeys will be used to re-initialize openKeys when sidebar is re-opened
          setSavedOpenKeys(openKeys)
          // control open submenus manually
          setOpenKeys(updatedOpenKeys)
        }}
        onSelect={({key}) => {
          setSelectedKeys([key])
        }}/>
    </Sider>
  )
}

export default Sidebar
