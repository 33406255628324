// if false, triggers initial fetch of feature flag data from API in App.tsx
export const isFeatureFlagsLoaded = state => !!state.featureFlags;

// retrieves feature flag state from Redux for use in setting up feature decisions
// see featureDecisions.ts
export const getFeatureFlags = state => state.featureFlags;

// no separate slice for pw expiration window params, so put these selectors here

// retrieves value of the feature flag with featureName: pwResetExpirationMinutes
// used to customize the valid lifetime of a pw reset email generated when resetting a login record's pw
// by calling POST /Logins/ResetPassword
export const getResetEmailLifetime = state => state.featureFlags?.pwResetExpirationMinutes?.value

// retrieves value of the feature flag with featureName: pwCreateExpirationMinutes
// used to customize the valid lifetime of a pw create email generated when creating a new login record
// by calling POST /Logins and POST /customers/_import.
export const getCreateEmailLifetime = state => state.featureFlags?.pwCreateExpirationMinutes?.value
